<template>
  <b-sidebar
    id="submission-brief-detail"
    :visible="!!selectedSubmission"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="() => $emit('update:selected-submission', null)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Submission Detail
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- Body -->
      <validation-observer
        #default="{handleSubmit}"
        ref="refFormObserver"
      >
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <b-form-group label="ID">
            <h5>{{selectedSubmission._id}}</h5>
          </b-form-group>

          <b-form-group label="slug">
            <h5>{{selectedSubmission.slug}}</h5>
          </b-form-group>

          <b-form-group label="Entity" v-if="selectedSubmission.entityType">
            <h5>{{selectedSubmission.entityType}}</h5>
          </b-form-group>

          <b-form-group label="Applying State" v-if="selectedSubmission.applyingState">
            <h5>{{selectedSubmission.applyingState}}</h5>
          </b-form-group>

          <b-form-group label="Package" v-if="selectedSubmission.packageType">
            <h5>{{selectedSubmission.packageType}}</h5>
          </b-form-group>

          <b-form-group label="Payment Method" v-if="selectedSubmission.paymentMethod">
            <h5>{{selectedSubmission.paymentMethod}}</h5>
          </b-form-group>

          <validation-provider
            #default="validationContext"
            name="Assignee"
            rules="required"
          >
            <b-form-group label="Assign Staff">
              <v-select
                v-model="assigneeId"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="assigneeOptions"
                :reduce="val => val.value"
                :clearable="false"
                input-id="user-role"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              Update
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancel
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref, toRefs } from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import store from '@/store'
import api from '@/api'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,

    ToastificationContent,

    ValidationObserver,
    ValidationProvider
  },
  directives: {
    Ripple,
  },
  props: {
    selectedSubmission: {
      type: Object,
      required: true
    },
    assigneeOptions: {
      type: Array,
      required: true
    },
  },

  setup(props, {emit}) {
    const toast = useToast()
    const assigneeId = ref(props.selectedSubmission.assignee._id)
    const resetSubmissionChanges = () => {
      assigneeId.value = null
    }

    const onSubmit = () => {
      api.submissions.assignStaff(props.selectedSubmission._id, assigneeId.value)
        .then(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Assigned staff successfully.',
              icon: 'EditIcon',
              variant: 'success',
            },
          })

          emit('update:selected-submission', null)
          emit('refetch-submissions')
        })
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetSubmissionChanges)

    return {
      assigneeId,
      onSubmit,

      refFormObserver,
      getValidationState,
      resetForm
    }
  }
}
</script>
