<template>
  <div>
    <submission-brief-detail
      v-if="selectedSubmission"
      :selected-submission.sync="selectedSubmission"
      :assignee-options="adminsList"
      @refetch-submissions="refetchData()"
    />

    <!-- Table Container Card -->
    <b-card
      no-body
    >

      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Entries</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block ml-50 mr-1"
            />
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                debounce="500"
                class="d-inline-block mr-1"
                placeholder="Search by slug..."
              />
              <v-select
                v-model="statusFilter"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="statusOptions"
                class="submission-filter-select"
                placeholder="Select Status"
              >
                <template #selected-option="{ label }">
                  <span class="text-truncate overflow-hidden">
                    {{ label }}
                  </span>
                </template>
              </v-select>
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refSubmissionListTable"
        :items="fetchSubmissions"
        responsive
        :fields="tableColumns"
        primary-key="_id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
        class="position-relative"
      >

        <!-- Column: Id -->
        <template #cell(_id)="data">
          {{ data.value.slice(-4) }}
        </template>

        <template #cell(slug)="data">
          <b-link
            :to="{ name: 'submission-detail', params: { id: data.item._id }}"
          >
            {{ data.item.slug }}
          </b-link>
          <b-badge
            v-if="data.item.children.length > 0"
            :id="`submission-row-${data.item._id}-children`"
            pill
            variant="info"
          >
            +{{ childrenCount(data.item) }}
          </b-badge>
          <b-tooltip
            v-if="data.item.children.length > 0"
            :target="`submission-row-${data.item._id}-children`"
            placement="bottom"
            offset="10"
          >
            <ul class="list-unstyled m-0">
              <li
                v-for="(item, id) in childrenList(data.item)"
                :key="id"
                class="mb-0"
              >
                {{ item }}
              </li>
            </ul>
          </b-tooltip>
        </template>

        <!-- Column: Client -->
        <template #cell(client)="data">
          {{ data.item.client.email }}
          <b-link
            :to="{ name: 'user-detail', params: { id: data.item.client._id }}"
            target="_blank"
          >
            <feather-icon
              icon="LinkIcon"
            />
          </b-link>
        </template>

        <!-- Column: Assignee -->
        <template #cell(assignee)="data">
          {{ data.item.assignee.email }}
          <b-link
            :to="{ name: 'user-detail', params: { id: data.item.assignee._id }}"
            target="_blank"
            v-if="!!data.item.assignee._id"
          >
            <feather-icon
              icon="LinkIcon"
            />
          </b-link>
        </template>

        <!-- Column: createdAt -->
        <template #cell(createdAt)="data">
          {{ new Date(data.item.createdAt).toLocaleString() }}
        </template>

        <!-- Column: Status -->
        <template #cell(status)="data">
          <b-badge
            pill
            :variant="`light-${resolveSubmissionStatusVariant(data.item.status)}`"
          >
            {{ capitalize(data.item.status) }}
          </b-badge>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">

          <div class="text-nowrap">
            <feather-icon
              :id="`submission-row-${data.item._id}-detail-icon`"
              icon="EyeIcon"
              class="cursor-pointer"
              size="16"
              @click="$router.push({ name: 'submission-detail', params: { id: data.item._id }})"
            />
            <b-tooltip
              title="Detail"
              :target="`submission-row-${data.item._id}-detail-icon`"
            />
            
            <feather-icon
              :id="`submission-row-${data.item._id}-edit-icon`"
              icon="EditIcon"
              size="16"
              class="mx-1 cursor-pointer"
              @click="selectedSubmission=data.item"
            />
            <b-tooltip
              title="Edit"
              :target="`submission-row-${data.item._id}-edit-icon`"
            />
          </div>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalSubmissions"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BTable, BMedia, BAvatar, BLink,
  BBadge, BPagination, BTooltip,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import vSelect from 'vue-select'
import { ref, onUnmounted } from '@vue/composition-api'
import store from '@/store'
import api from '@/api'
import capitalize from 'lodash.capitalize'
import useSubmissionsList from './useSubmissionList'
import { resolveSubmissionStatusVariant } from '@/common/Variants.js'
import SubmissionBriefDetail from './SubmissionBriefDetail.vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BPagination,
    BTooltip,
    SubmissionBriefDetail,

    vSelect,
  },
  setup() {
    const {
      fetchSubmissions,
      tableColumns,
      perPage,
      currentPage,
      totalSubmissions,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refSubmissionListTable,

      statusOptions,
      statusFilter,

      refetchData,

      childrenCount,
      childrenList,
    } = useSubmissionsList({assignee: null, client: null})

    const selectedSubmission = ref(null)
    const adminsList = ref([])
    api.users.fetchUsers({
      role: 'admin',
      status: 'active',
    })
    .then(response => {
      adminsList.value = response.data.users.map(user => ({label: `${user.name || 'No name'} (${user.email})`, value: user._id}))
    })


    return {
      fetchSubmissions,
      tableColumns,
      perPage,
      currentPage,
      totalSubmissions,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refSubmissionListTable,

      statusOptions,
      statusFilter,

      refetchData,

      childrenCount,
      childrenList,

      selectedSubmission,
      adminsList,

      avatarText,

      resolveSubmissionStatusVariant,

      capitalize,
      childrenCount,
      childrenList
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.submission-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
